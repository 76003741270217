import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import ErrorModal from "./ErrorModal";
import { postFormData } from "./responseURL.api";
import { phoneValidate } from "./utils/validations";

const App = () => {
    // Get the current Date
    let currentDate = new Date();
    currentDate = currentDate.toISOString().split("T")[0];

    // Get the maximum date (30 days from current date)
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);
    maxDate = maxDate.toISOString().split("T")[0];

    // Initial data
    let initialUserValues = {
        order_no: "",
        order_no_error: false,
        empty_order_no: true,

        product_name: "",
        product_name_error: false,
        empty_product_name: true,

        delivery_date: currentDate,
        delivery_date_error: false,

        maxDate,

        delivery_time_slot: "",
        delivery_time_slot_error: false,

        message_card: "",

        recipient_name: "",

        recipient_contact_number: "",
        recipient_contact_number_error: false,

        recipient_address: "",
        recipient_address_error: false,

        sender_name: "",
        sender_name_error: false,
        empty_sender_name: true,

        contact_number: "",
        contact_number_error: false,

        email_address: "",
        email_address_error: false,
        empty_email_address: true,

        password: "",
        password_error: false,

        token: "",
        empty_token: true,

        seq: "",
        empty_seq: true,
    };

    const [userValues, setUserValues] = useState();
    const [submittedValues, setSubmittedValues] = useState(false);
    const [isAPIError, setIsAPIError] = useState(true);
    const [incorrectUserData, setIncorrectUserData] = useState(true);
    const [loadingAPI, setLoadingAPI] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState("");

    useEffect(() => {
        initialiseData();
        // eslint-disable-next-line
    }, []);

    const initialiseData = () => {
        // Get current URL
        let searchURL = window.location.search;
        searchURL = searchURL.slice(1);

        let params = searchURL.split("&");

        // Loop through every url parameter
        params.map((param) => {
            // Split every value with "=" sign
            // Example - token=1234xyz
            const paramValue = param.split("=");

            if (paramValue[0] === "name") {
                initialUserValues.sender_name = decodeURIComponent(
                    paramValue[1]
                );
                initialUserValues.empty_sender_name = false;
            }
            if (paramValue[0] === "email") {
                initialUserValues.email_address = decodeURIComponent(
                    paramValue[1]
                );
                initialUserValues.empty_email_address = false;
            }
            if (paramValue[0] === "order_id") {
                initialUserValues.order_no = decodeURIComponent(paramValue[1]);
                initialUserValues.empty_order_no = false;
            }
            if (paramValue[0] === "product_name") {
                initialUserValues.product_name = decodeURIComponent(
                    paramValue[1]
                );
                initialUserValues.empty_product_name = false;
            }
            if (paramValue[0] === "token") {
                initialUserValues.token = decodeURIComponent(paramValue[1]);
                initialUserValues.empty_token = false;
            }
            if (paramValue[0] === "seq") {
                initialUserValues.seq = decodeURIComponent(paramValue[1]);
                initialUserValues.empty_seq = false;
            }

            return initialUserValues;
        });

        // If token or seq is not present, set flag to show password
        if (initialUserValues.token && initialUserValues.seq) {
            setIncorrectUserData(false);
        }

        console.log(initialUserValues);
        setUserValues(initialUserValues);
    };

    // Function to submit form data
    const submitFormData = () => {
        setLoadingAPI(true);

        // Get the required fields from state
        const bodyData = {
            contact_number: userValues.contact_number,
            delivery_date: userValues.delivery_date,
            delivery_time_slot: userValues.delivery_time_slot,
            email_address: userValues.email_address,
            message_card: userValues.message_card,
            order_no: userValues.order_no,
            product_name: userValues.product_name,
            recipient_address: userValues.recipient_address,
            recipient_contact_number: userValues.recipient_contact_number,
            recipient_name: userValues.recipient_name,
            sender_name: userValues.sender_name,
            token: userValues.token,
            seq: userValues.seq,
            password: userValues.password,
        };

        // API call
        postFormData(bodyData)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }

                setAlertErrorMessage(
                    "You have successfully submitted the delivery details"
                );
                setIsAPIError(false);
            })
            .catch((error) => {
                setAlertErrorMessage(
                    error?.response?.data?.message || undefined
                );
                setIsAPIError(true);
            })
            .finally(() => {
                setSubmittedValues(true);
                setLoadingAPI(false);
                initialiseData();
            });
    };

    // Function to check user data before API call
    const handleSubmitUserValues = (e) => {
        e.preventDefault();
        let userValuesCopy = { ...userValues };
        let errorFlag = false;

        // Check for each form field before submitting the form

        if (!userValuesCopy.order_no) {
            userValuesCopy["order_no_error"] = true;
            errorFlag = true;
        }

        if (!userValuesCopy.product_name) {
            userValuesCopy["product_name_error"] = true;
            errorFlag = true;
        }

        const inputDate = new Date(userValuesCopy.delivery_date);
        const todayDate = new Date(initialUserValues.delivery_date);
        let diff = inputDate - todayDate;
        diff = Math.floor(diff / 86400000);
        if (!userValuesCopy.delivery_date || diff > 30) {
            userValuesCopy["delivery_date_error"] = true;
            errorFlag = true;
        }

        if (!userValuesCopy.delivery_time_slot) {
            userValuesCopy["delivery_time_slot_error"] = true;
            errorFlag = true;
        }

        if (
            !userValuesCopy.recipient_contact_number ||
            phoneValidate(userValuesCopy.recipient_contact_number)
        ) {
            userValuesCopy["recipient_contact_number_error"] = true;
            errorFlag = true;
        }

        if (!userValuesCopy.recipient_address) {
            userValuesCopy["recipient_address_error"] = true;
            errorFlag = true;
        }

        if (!userValuesCopy.sender_name) {
            userValuesCopy["sender_name_error"] = true;
            errorFlag = true;
        }

        if (
            !userValuesCopy.contact_number ||
            phoneValidate(userValuesCopy.contact_number)
        ) {
            userValuesCopy["contact_number_error"] = true;
            errorFlag = true;
        }

        if (
            !userValuesCopy.email_address ||
            !isEmail(userValuesCopy.email_address)
        ) {
            userValuesCopy["email_address_error"] = true;
            errorFlag = true;
        }

        if (
            (!userValuesCopy.token || !userValuesCopy.seq) &&
            !userValuesCopy.password
        ) {
            userValuesCopy["password_error"] = true;
            errorFlag = true;
        }

        setUserValues(userValuesCopy);

        if (errorFlag) {
            return;
        }

        // If every check passes, call the API
        submitFormData();
    };

    // Function to set user data on each keypress
    const handleUserValues = (event) => {
        const inputValue = event.target.value;
        const inputId = event.target.id;
        let userValuesCopy = { ...userValues };

        // Return if the input value exceeds certain limit
        if (
            // Check for message card data
            (inputId === "message_card" && inputValue.length > 2500) ||
            // Check for recipient name data
            (inputId === "recipient_name" && inputValue.length > 500) ||
            // Check for recipient contact number data
            (inputId === "recipient_contact_number" &&
                inputValue.length > 11) ||
            // Check for recipient address data
            (inputId === "recipient_address" && inputValue.length > 500) ||
            // Check for sender name data
            (inputId === "sender_name" && inputValue.length > 200) ||
            // Check for recipient contact number data
            (inputId === "contact_number" && inputValue.length > 11)
        ) {
            return;
        }

        userValuesCopy[inputId] = inputValue;
        userValuesCopy[`${inputId}_error`] = false;
        setUserValues(userValuesCopy);
    };

    return (
        <div className="container mt-5">
            {userValues ? (
                <form className="row g-3 border p-3 m-4">
                    <div className="mb-3">
                        <label htmlFor="order_no" className="form-label">
                            Order Number
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                userValues.order_no_error ? `input-error` : ``
                            }`}
                            id="order_no"
                            placeholder="Enter order number"
                            value={userValues.order_no}
                            disabled={!userValues.empty_order_no || loadingAPI}
                            readOnly={!userValues.empty_order_no}
                            onChange={(e) => handleUserValues(e)}
                        />
                        <div
                            className={`${
                                userValues.order_no_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.order_no_error &&
                                "Please enter the order number"}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="product_name" className="form-label">
                            Product Name
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                userValues.product_name_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="product_name"
                            placeholder="Enter product name"
                            value={userValues.product_name}
                            disabled={
                                !userValues.empty_product_name || loadingAPI
                            }
                            readOnly={!userValues.empty_product_name}
                            onChange={(e) => handleUserValues(e)}
                        />
                        <div
                            className={`${
                                userValues.product_name_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.product_name_error &&
                                "Please enter the product name"}
                        </div>
                    </div>

                    <div className="container mb-3">
                        <label htmlFor="delivery_date" className="form-label">
                            Delivery Date
                        </label>
                        <input
                            type="date"
                            className={`form-control ${
                                userValues.delivery_date_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="delivery_date"
                            value={userValues.delivery_date}
                            onChange={(e) => handleUserValues(e)}
                            min={initialUserValues.delivery_date}
                            max={initialUserValues.maxDate}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`${
                                userValues.delivery_date_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.delivery_date_error &&
                                "Delivery date should be only 30 days later from today"}
                        </div>
                    </div>

                    <div className="container mb-3">
                        <label
                            htmlFor="delivery_time_slot"
                            className="form-label"
                        >
                            Delivery Time Slot
                        </label>
                        <select
                            className={`form-select ${
                                userValues.delivery_time_slot_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="delivery_time_slot"
                            value={userValues.delivery_time_slot}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        >
                            <option disabled value="">
                                Time Slot
                            </option>
                            <option>Between 9am to 6pm</option>
                        </select>
                        <div
                            className={`${
                                userValues.delivery_time_slot_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.delivery_time_slot_error &&
                                "Please select one time slot"}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="message_card" className="form-label">
                            Message Card
                        </label>
                        <textarea
                            className="form-control"
                            id="message_card"
                            rows="3"
                            value={userValues.message_card}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`input_length ${
                                userValues.message_card.length === 0
                                    ? `error_input_length`
                                    : `success_input_length`
                            }`}
                        >
                            {userValues.message_card.length}/2500
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="recipient_name" className="form-label">
                            Recipient Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="recipient_name"
                            placeholder="Enter recipient name"
                            value={userValues.recipient_name}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`input_length ${
                                userValues.recipient_name.length === 0
                                    ? `error_input_length`
                                    : `success_input_length`
                            }`}
                        >
                            {userValues.recipient_name.length}/500
                        </div>
                    </div>

                    <div>
                        <label
                            htmlFor="recipient_contact_number"
                            className="form-label"
                        >
                            Recipient Contact Number
                        </label>
                        <input
                            type="number"
                            className={`form-control ${
                                userValues.recipient_contact_number_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="recipient_contact_number"
                            placeholder="Enter recipient contact number"
                            value={userValues.recipient_contact_number}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`input_length ${
                                userValues.recipient_contact_number.length ===
                                    0 ||
                                userValues.recipient_contact_number_error
                                    ? `error_input_length`
                                    : `success_input_length`
                            }`}
                        >
                            {userValues.recipient_contact_number.length}/11
                        </div>
                        <div
                            className={`${
                                userValues.recipient_contact_number_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.recipient_contact_number_error &&
                                "Mobile number should be in the format 01xxxx, between 10 to 11 digits"}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label
                            htmlFor="recipient_address"
                            className="form-label"
                        >
                            Recipient Full Address
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                userValues.recipient_address_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="recipient_address"
                            placeholder="Enter recipient address"
                            value={userValues.recipient_address}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`input_length ${
                                userValues.recipient_address.length === 0
                                    ? `error_input_length`
                                    : `success_input_length`
                            }`}
                        >
                            {userValues.recipient_address.length}/500
                        </div>
                        <div
                            className={`${
                                userValues.recipient_address_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.recipient_address_error &&
                                "Please enter recipient address"}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="sender_name" className="form-label">
                            Sender Name
                        </label>
                        <input
                            type="text"
                            className={`form-control ${
                                userValues.sender_name_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="sender_name"
                            placeholder="Enter sender name"
                            value={userValues.sender_name}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`input_length ${
                                userValues.sender_name.length === 0
                                    ? `error_input_length`
                                    : `success_input_length`
                            }`}
                        >
                            {userValues.sender_name.length}/200
                        </div>
                        <div
                            className={`${
                                userValues.sender_name_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.sender_name_error &&
                                "Please enter sender name"}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="contact_number" className="form-label">
                            Sender Contact Number
                        </label>
                        <input
                            type="number"
                            className={`form-control ${
                                userValues.contact_number_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="contact_number"
                            placeholder="Enter sender contact number"
                            value={userValues.contact_number}
                            onChange={(e) => handleUserValues(e)}
                            disabled={loadingAPI}
                        />
                        <div
                            className={`input_length ${
                                userValues.contact_number.length === 0 ||
                                userValues.contact_number_error
                                    ? `error_input_length`
                                    : `success_input_length`
                            }`}
                        >
                            {userValues.contact_number.length}/11
                        </div>
                        <div
                            className={`${
                                userValues.contact_number_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.contact_number_error &&
                                "Mobile number should be in the format 01xxxx, between 10 to 11 digits"}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email_address" className="form-label">
                            Email Address
                        </label>
                        <input
                            type="email"
                            className={`form-control ${
                                userValues.email_address_error
                                    ? `input-error`
                                    : ``
                            }`}
                            id="email_address"
                            placeholder="Enter email address"
                            value={userValues.email_address}
                            disabled={
                                !userValues.empty_email_address || loadingAPI
                            }
                            readOnly={!userValues.empty_email_address}
                            onChange={(e) => handleUserValues(e)}
                        />
                        <div
                            className={`${
                                userValues.email_address_error
                                    ? `visible error_message`
                                    : `invisible`
                            }`}
                        >
                            {userValues.email_address_error &&
                                "Please enter a valid email address"}
                        </div>
                    </div>

                    {incorrectUserData && (
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Password
                            </label>
                            <input
                                type="password"
                                className={`form-control ${
                                    userValues.password_error
                                        ? `input-error`
                                        : ``
                                }`}
                                id="password"
                                placeholder="Enter password"
                                value={userValues.password}
                                onChange={(e) => handleUserValues(e)}
                                disabled={loadingAPI}
                            />
                            <div
                                className={`${
                                    userValues.password_error
                                        ? `visible error_message`
                                        : `invisible`
                                }`}
                            >
                                {userValues.password_error &&
                                    "Please enter a valid password"}
                            </div>
                        </div>
                    )}

                    <div className="col-12">
                        <button
                            className="btn btn-primary submit-button"
                            type="submit"
                            onClick={handleSubmitUserValues}
                            disabled={loadingAPI}
                        >
                            {loadingAPI ? (
                                <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                "Submit form"
                            )}
                        </button>
                    </div>
                </form>
            ) : (
                <div className="incorrect-fields">
                    All Fields are not provided
                </div>
            )}

            {submittedValues && (
                <ErrorModal
                    setSubmittedValues={setSubmittedValues}
                    message={alertErrorMessage}
                    isAPIError={isAPIError}
                />
            )}
        </div>
    );
};

export default App;
