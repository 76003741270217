// https://api-flowerchimp.go-shop.com.my

import axios from "axios";

const postFormURL = "https://api-flowerchimp.go-shop.com.my";

export const postFormData = async (body) => {
    const response = await axios({
        method: "post",
        url: postFormURL,
        data: body,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return response;
};
